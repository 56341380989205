import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loaders from "../components/Loader";
// Pages
const Home = lazy(() => import("../pages/Home"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const Teams = lazy(() => import("../pages/Teams"));
const MediaEvents = lazy(() => import("../pages/MediaEvents"));
const MediaEventscopy = lazy(() => import("../pages/MediaEventscopy"));
const WhyJunoCampus = lazy(() => import("../pages/WhyJunoCampus"));
const Offerings = lazy(() => import("../pages/Offerings"));
const Architecture = lazy(() => import("../pages/Architecture"));
const Clientele = lazy(() => import("../pages/Clientele"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const App = (props) => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loaders />}>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/team-leadership" element={<Teams />} />
          <Route path="/media-events" element={<MediaEvents />} />
          <Route path="/media-eventss" element={<MediaEventscopy />} />
          <Route path="/why-juno-campus" element={<WhyJunoCampus />} />
          <Route path="/offerings" element={<Offerings />} />
          <Route path="/architecture" element={<Architecture />} />
          <Route path="/clientele" element={<Clientele />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
