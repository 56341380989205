import React from "react";

import LOGO from "../../assets/img/loader.png";
const Loaders = (props) => {
  return (
    <>
      <div className="loader">
        <img src={LOGO} alt="..." />
      </div>
    </>
  );
};
export default Loaders;
